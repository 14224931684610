import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeMultigroup
    ? _c(
        VContainer,
        { attrs: { "grid-list-xl": "" } },
        [
          _c(
            VLayout,
            { attrs: { row: "", wrap: "", "justify-space-between": "" } },
            [
              _c(VFlex, { attrs: { xs8: "" } }, [_c("view-title")], 1),
              _vm.canAddSite
                ? _c(
                    VFlex,
                    { staticClass: "display-flex justify-end xs3" },
                    [
                      _c(
                        "div",
                        { staticClass: "FAB-Extended-PrimaryColor-Enabled" },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { type: "submit", color: "primary" },
                              on: { click: _vm.exportSites }
                            },
                            [
                              _c(VIcon, { attrs: { left: "", dark: "" } }, [
                                _vm._v("mdi-download")
                              ]),
                              _vm._v("EXPORT SITES\n        ")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "FAB-Extended-PrimaryColor-Enabled" },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { type: "submit", color: "primary" },
                              on: { click: _vm.addNewSite }
                            },
                            [
                              _c(VIcon, { attrs: { left: "", dark: "" } }, [
                                _vm._v("mdi-plus")
                              ]),
                              _vm._v("ADD NEW SITE\n        ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                VFlex,
                { attrs: { xs7: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      label: "Search Sites",
                      "single-line": "",
                      solo: "",
                      "prepend-inner-icon": "mdi-magnify",
                      clearable: ""
                    },
                    on: {
                      change: _vm.updateData,
                      "click:clear": _vm.resetTable
                    },
                    model: {
                      value: _vm.filter,
                      callback: function($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter"
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                [
                  _c(VSelect, {
                    attrs: {
                      value: _vm.activeMultigroup,
                      items: _vm.multigroupOptions,
                      "item-text": "name",
                      label: "Select App",
                      "return-object": "",
                      solo: "",
                      "single-line": ""
                    },
                    on: { change: _vm.changeMultigroup }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(
                    VDataTable,
                    {
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.availableSites,
                        "server-items-length": _vm.totalItems,
                        loading: _vm.loading,
                        pagination: _vm.pagination,
                        "total-items": _vm.totalItems,
                        page: _vm.currentPage,
                        "rows-per-page-items": [10, 50, 100],
                        "no-data-text": _vm.loading
                          ? "Please wait while data is loading"
                          : "No sites found"
                      },
                      on: {
                        "update:pagination": function($event) {
                          _vm.pagination = $event
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "items",
                            fn: function(props) {
                              return [
                                _c(
                                  "tr",
                                  {
                                    staticClass: "clickable",
                                    on: {
                                      click: function($event) {
                                        return _vm.goToSite({
                                          id: props.item.id,
                                          name: props.item.name,
                                          multigroupId: props.item.multigroup_id
                                        })
                                      }
                                    }
                                  },
                                  [
                                    _c("td", [_vm._v(_vm._s(props.item.name))]),
                                    _c("td", [
                                      _vm._v(_vm._s(props.item.sector_name))
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(props.item.address.country))
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(props.item.address.city))
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(props.item.platform))
                                    ]),
                                    _c(
                                      "td",
                                      _vm._l(props.item.type_of_kds, function(
                                        kds,
                                        k
                                      ) {
                                        return _c(
                                          VChip,
                                          {
                                            key: k,
                                            staticClass: "kds",
                                            attrs: {
                                              disabled: "",
                                              draggable: "false"
                                            }
                                          },
                                          [_vm._v(_vm._s(kds))]
                                        )
                                      }),
                                      1
                                    ),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "span",
                                                        _vm._g({}, on),
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                props.item
                                                                  .unit_ids
                                                                  .length > 2
                                                                  ? props.item
                                                                      .unit_ids[0] +
                                                                      ", " +
                                                                      props.item
                                                                        .unit_ids[1] +
                                                                      ",..."
                                                                  : props.item.unit_ids.join(
                                                                      ", "
                                                                    )
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  props.item.unit_ids.join(", ")
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3004282398
                      )
                    },
                    [
                      _c(VProgressLinear, {
                        attrs: {
                          color: "blue",
                          indeterminate: "",
                          height: "7"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "progress",
                              fn: function() {
                                return undefined
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          63074868
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }