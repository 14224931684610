<template>
  <v-container grid-list-xl v-if="activeMultigroup">
    <v-layout row wrap justify-space-between>
      <v-flex xs8>
        <view-title />
      </v-flex>
      <v-flex class="display-flex justify-end xs3" v-if="canAddSite">
        <div class="FAB-Extended-PrimaryColor-Enabled">
          <v-btn type="submit" color="primary" @click="exportSites">
            <v-icon left dark>mdi-download</v-icon>EXPORT SITES
          </v-btn>
        </div>
        <div class="FAB-Extended-PrimaryColor-Enabled">
          <v-btn type="submit" color="primary" @click="addNewSite">
            <v-icon left dark>mdi-plus</v-icon>ADD NEW SITE
          </v-btn>
        </div>
      </v-flex>
      <v-flex xs7>
        <v-text-field
          label="Search Sites"
          single-line
          solo
          prepend-inner-icon="mdi-magnify"
          clearable
          v-model="filter"
          @change="updateData"
          @click:clear="resetTable"
        ></v-text-field>
      </v-flex>
      <v-flex>
        <v-select
          :value="activeMultigroup"
          :items="multigroupOptions"
          item-text="name"
          label="Select App"
          return-object
          @change="changeMultigroup"
          solo
          single-line
        ></v-select>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="availableSites"
          :server-items-length="totalItems"
          :loading="loading"
          :pagination.sync="pagination"
          :total-items="totalItems"
          :page="currentPage"
          :rows-per-page-items="[10, 50, 100]"
          :no-data-text="loading ? 'Please wait while data is loading' : 'No sites found'"
        >
          <v-progress-linear
            v-slot:progress
            color="blue"
            indeterminate
            height="7"
          ></v-progress-linear>
          <template v-slot:items="props">
            <tr
              @click="
                goToSite({
                  id: props.item.id,
                  name: props.item.name,
                  multigroupId: props.item.multigroup_id,
                })
              "
              class="clickable"
            >
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.sector_name }}</td>
              <td>{{ props.item.address.country }}</td>
              <td>{{ props.item.address.city }}</td>
              <td>{{ props.item.platform }}</td>
              <td>
                <v-chip
                  disabled
                  draggable="false"
                  class="kds"
                  v-for="(kds, k) in props.item.type_of_kds"
                  v-bind:key="k"
                  >{{ kds }}</v-chip
                >
              </td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{
                        props.item.unit_ids.length > 2
                          ? `${props.item.unit_ids[0]}, ${props.item.unit_ids[1]},...`
                          : props.item.unit_ids.join(', ')
                      }}
                    </span>
                  </template>
                  <span>{{ props.item.unit_ids.join(', ') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import JsonExcel from 'vue-json-excel';
import XLSX from 'xlsx';
import { normalizeSites } from '@/helpers/normalizeSites';
import { defaultAppID } from '@/constants';

Vue.component('downloadExcel', JsonExcel);

export default {
  name: 'SitesListing',
  data: () => ({
    headers: [
      {
        text: 'Site Name',
        align: 'left',
        value: 'name',
      },
      {
        text: 'Sector',
        align: 'left',
        value: 'sector_name',
      },
      {
        text: 'Country',
        align: 'left',
        value: 'address.country',
      },
      {
        text: 'City',
        align: 'left',
        value: 'address.city',
      },
      {
        text: 'Platform',
        align: 'left',
        value: 'platform',
      },
      {
        text: 'Type of KDS',
        align: 'left',
        value: 'type_of_kds',
      },
      {
        text: 'Unit #',
        align: 'left',
        value: 'unit_ids',
      },
    ],
    loading: false,
    filter: '',
    totalItems: 0,
    currentPage: 1,
    pagination: {},
    appID: localStorage.siteDefaultAppID ? localStorage.siteDefaultAppID : defaultAppID,
  }),
  methods: {
    exportSites() {
      // Format data
      this.loading = true;
      let site_data = this.availableSites.map((site) => {
        return {
          'Site Name': site.name,
          Sector: site.sector_name || 'N/A',
          Country: site.address.country || 'N/A',
          City: site.address.city || 'N/A',
          Platform: site.platform || 'N/A',
          'Type of KDS': site.type_of_kds.toString(),
          'Unit #': site.unit_ids.length ? String(site.unit_ids.join(', ')) : 'N/A',
        };
      });
      // Here we check if something is searched, then it applies filter
      if (this.filter.trim().length) {
        site_data = site_data.filter((site) =>
          JSON.stringify(Object.values(site))
            .toLowerCase()
            .includes(this.filter.toLowerCase()),
        );
      }
      site_data.sort((a, b) => (a['Site Name'] > b['Site Name'] ? 1 : -1));
      const data = XLSX.utils.json_to_sheet(site_data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      XLSX.writeFile(wb, 'sites.xlsx');
      this.loading = false;
    },
    goToSite({ name, id, multigroupId }) {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 1,
        title: name,
        to: { name: 'site-info', params: { site_id: id, app: multigroupId } },
      });
      this.$router.push({
        name: 'site-info',
        params: {
          site_id: id,
          app: multigroupId,
        },
      });
    },
    goToSitesListing(id) {
      this.$router.push({
        name: 'sites-listing',
        params: {
          app: id,
        },
      });
    },
    addNewSite() {
      this.$router.push({
        name: 'site-new',
      });
    },
    async changeMultigroup(e) {
      this.loading = true;
      let options;
      if (e.name !== 'All') options = { id: e.id };
      await this.getFetchPaginated(options);

      if (!localStorage.siteDefaultAppID || localStorage.siteDefaultAppID !== e.id) {
        localStorage.siteDefaultAppID = e.id;
        this.appID = e.id;
      }
      this.goToSitesListing(e.id);
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 0,
        title: `${this.activeMultigroup.name} Sites`,
        to: { name: 'sites-listing', params: { app: this.activeMultigroup.id } },
      });
      this.pagination.page = 1;
      this.updateData();
    },
    async getFetchPaginated(options) {
      try {
        await this.$store.dispatch('sites/fetchPaginated', options);
        this.loading = false;
        const { meta, results } = JSON.parse(JSON.stringify(this.fetchPaginated));
        this.items = results;
        this.totalItems = meta.totalResults;
        this.currentPage = meta.page;
      } catch (err) {
        console.error(err);
        this.$toast.error('Could not fetch application data');
      }
    },
    resetTable() {
      this.filter = '';
      this.updateData();
    },
    async updateData(pagination = undefined) {
      this.loading = true;
      const page = (pagination && pagination.page) || 1;
      const limit = (pagination && pagination.rowsPerPage) || 10;
      const defaultOptions = { limit, page, filter: this.filter ? this.filter.trim() : undefined };
      if (this.activeMultigroup.id === 'all') {
        await this.getFetchPaginated(defaultOptions);
      } else {
        await this.getFetchPaginated({
          id: this.activeMultigroup.id,
          ...defaultOptions,
        });
      }
    },
  },
  computed: {
    ...mapState('adminPanel', ['active_user_id']),
    ...mapGetters('adminPanel', {
      hasRole: 'hasRole',
    }),
    ...mapState('sites', ['multigroups', 'multigroupMap', 'siteMap', 'fetchPaginated']),
    multigroupOptions() {
      return [{ name: 'All', id: 'all' }, ...this.multigroups];
    },
    activeMultigroup() {
      if (this.$route.params.app === 'all') {
        return { name: 'All', id: 'all' };
      }
      return this.multigroups.find((e) => e.id === this.$route.params.app);
    },
    canAddSite() {
      if (this.$route.params.app === 'all') {
        return ['admin'].some((r) => this.hasRole(r));
      }
      return ['admin'].some((r) => this.hasRole(r));
    },
    selectedApp() {
      return this.$route.params.app;
    },
    availableSites() {
      const { results } = JSON.parse(JSON.stringify(this.fetchPaginated));
      return normalizeSites(results);
    },
  },
  watch: {
    pagination: {
      handler(pagination) {
        this.updateData(pagination);
      },
      deep: true,
    },
    selectedApp(v) {
      if (!v) {
        this.goToSitesListing(this.appID);
      }
    },
  },
  async mounted() {
    if (this.$route.params.app !== this.appID) {
      await this.goToSitesListing(this.appID);
    }

    if (!this.activeMultigroup) {
      this.$toast.error('Could not determine application data');
      return;
    }

    this.loading = true;

    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: `${this.activeMultigroup.name} Sites`,
      to: { name: 'sites-listing', params: { app: this.activeMultigroup.id } },
    });

    this.updateData();
  },
};
</script>

<style scoped>
.kds {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.15px;
  color: #000000;
}
@media only screen and (min-width: 1264px) {
  .container {
    max-width: 1450px;
  }
}
</style>
